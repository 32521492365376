import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.label`
position: relative;
display: inline-block;
align-self: center;
width: 50px;
height: 28px;
`
const SwitchSpan = styled.span`
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #c7cde0;
-webkit-transition: .4s;
transition: .4s;
border-radius: 14px;
max-width: 50px;
height: 26;
&:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
`

const SwitchInput = styled.input.attrs({
  type: "checkbox"
})`
opacity: 0;
width: 0;
height: 0;
&:checked + ${SwitchSpan} {
  background-color: #008858;
  &:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }
}
`

const Switch = ({checked, onChange}) => {
  return(
    <Wrapper>
      <SwitchInput checked={checked} onChange={onChange} />
      <SwitchSpan />
    </Wrapper>
  )
}

export default Switch;